export default {
  form: {
    firstName: 'first name',
    lastName: 'last name',
    favoriteLocation: {
      label: 'preferred location',
      placeholder: 'Select one',
      help: 'Locations denoted with * are coming soon.',
    },
    phoneNumber: {
      label: 'phone number',
      legal: 'Used to verify your account. Standard messaging rates may apply.',
      placeholder: '555-555-5555',
    },
    smsOptIn: {
      label:
        'Get the latest %{merchantName} news and surprise announcements via SMS',
      description:
        'By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from %{merchantName} at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. <a target="_blank" class="grey-70-text bold grey-70-hover underline" href="%{termsOfServiceUrl}">View Terms</a> & <a target="_blank" class="grey-70-text bold grey-70-hover underline" href="%{privacyPolicyUrl}">Privacy Policy</a>.',
    },
    button: 'Next',
    errors: {
      generic: 'We had trouble updating your information. Please try again.',
      phone: 'That phone number is already registered.',
    },
  },
  skipButton: 'Do this later',
}
