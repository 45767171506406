// @flow
import React from 'react'
import { Link } from '@reach/router'
import { Button } from '@thanx/uikit/button'
import { Spacer } from '@thanx/uikit/spacer'
import { Translate } from 'react-i18nify'
import Header from 'components/Signup/Header'
import Layout from 'components/Signup/Layout'

const Choose = () => {
  return (
    <Layout open>
      <Header className="mb-0">
        <Translate
          value="pages.choose.title"
          tag="p"
          dangerousHTML
          className="px-xl mb-0"
        />
      </Header>

      <Spacer size="l" />

      <Link to="../app" className="text-decoration-none">
        <Button className="w-100 mb-s">
          <Translate value="pages.choose.downloadButton" />
        </Button>
      </Link>
    </Layout>
  )
}

export default Choose
