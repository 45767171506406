// @flow
import { gql } from 'apollo-boost'

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $firstName: String!
    $lastName: String!
    $merchantId: ID!
    $favoriteLocation: String
    $phone: String
    $state: States
    $smsMarketing: Boolean
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      favoriteLocation: $favoriteLocation
      merchantId: $merchantId
      phone: $phone
      state: $state
      smsMarketing: $smsMarketing
    ) {
      user {
        phone
        firstName
        lastName
      }
      errors {
        message
        status
      }
    }
  }
`
