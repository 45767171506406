// @flow
import React from 'react'
import { Translate } from 'react-i18nify'
import { FormsyCheckbox } from '@thanx/uikit/checkbox'
import {
  generatePrivacyPolicyUrl,
  generateTermsOfServiceUrl,
} from 'utilities/finePrint'

const SmsOptIn = (props: React$ElementConfig<typeof FormsyCheckbox>) => {
  const merchant = props.merchant
  const appHandle =
    merchant.app && merchant.isBranded ? merchant.app.handle : ''

  return (
    <div id="smsOptInWrapper" className="d-flex align-items-start mb-s">
      <FormsyCheckbox name="smsMarketing" />

      <div className="d-flex flex-column flex-fill st-ah">
        <span className="grey-70-text mb-xs bold">
          <Translate
            value="pages.info.form.smsOptIn.label"
            merchantName={merchant.name}
          />
        </span>

        <span className="body-text-6 grey-50-text">
          <Translate
            value="pages.info.form.smsOptIn.description"
            merchantName={merchant.name}
            dangerousHTML
            privacyPolicyUrl={generatePrivacyPolicyUrl(appHandle)}
            termsOfServiceUrl={generateTermsOfServiceUrl(appHandle)}
          />
        </span>
      </div>
    </div>
  )
}

export default SmsOptIn
